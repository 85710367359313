import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
    donut: {
        series1: "#46B090",
        series2: "#C8BEA3",
    },
    area: {
        series1: "#46B090",
        series2: "#C8BEA3",
    },
};

export default {
    barChartSimpleColumn: {
        series: [
            {
                name: "اجمالي الحجوزات",
                data: [100, 120, 90, 50, 170, 130, 10, 50],
            },
        ],
        chartOptions: {
            chart: {
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "20%",
                    endingShape: "rounded",
                },
            },
            legend: {
                show: true,
                position: "top",
                horizontalAlign: "right",
                fontSize: "16px",
                fontFamily: "Montserrat",
                markers: {
                    width: 12,
                    height: 12,
                    offsetX: 5,
                },
            },
            colors: [chartColors.area.series1, chartColors.area.series2],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            tooltip: {
                shared: false,
            },
            xaxis: {
                categories: [
                    "حجوزات غير لائقة",
                    "حجوزات لائقة",
                    "مرحلة المختبر",
                    "مرحلة الطبيب",
                    "مرحلة الاشعة",
                    "طلبات مؤكدة",
                    "طلبات  غير مؤكدة",
                    "طلبات المنصة ",
                ],
            },
            yaxis: {
                // opposite: isRtl,
            },
        },
    },
    donutDashboardChart: {
        series: [90],
        chartOptions: {
            legend: {
                show: false,
                position: "left",
                fontSize: "16px",
                fontFamily: "Montserrat",
                horizontalAlign: "center",
                markers: {
                    width: 12,
                    height: 12,
                    offsetX: 5,
                },
            },
            colors: [chartColors.donut.series1],
            dataLabels: {
                enabled: false,
                formatter(val) {
                    return val;
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                fontSize: "2rem",
                                fontFamily: "Montserrat",
                            },
                            value: {
                                fontSize: "1rem",
                                fontFamily: "Montserrat",
                                formatter(val) {
                                    // eslint-disable-next-line radix
                                    return val;
                                },
                            },
                            total: {
                                show: true,
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                label: "مجموع الحجوزات",
                                formatter() {
                                    return "125";
                                },
                            },
                        },
                    },
                },
            },
            labels: ["مجموع الحجوزات"],
            responsive: [
                {
                    breakpoint: 992,
                    options: {
                        chart: {
                            height: 380,
                        },
                        legend: {
                            position: "left",
                        },
                    },
                },
                {
                    breakpoint: 576,
                    options: {
                        chart: {
                            height: 320,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize: "1.5rem",
                                        },
                                        value: {
                                            fontSize: "1rem",
                                        },
                                        total: {
                                            fontSize: "1.5rem",
                                        },
                                    },
                                },
                            },
                        },
                        legend: {
                            show: true,
                            position: "left",
                        },
                    },
                },
            ],
        },
    },
    donutChart: {
        series: [90, 35],
        chartOptions: {
            legend: {
                show: true,
                position: "left",
                fontSize: "16px",
                fontFamily: "Montserrat",
                horizontalAlign: "center",
                markers: {
                    width: 12,
                    height: 12,
                    offsetX: 5,
                },
            },
            colors: [chartColors.donut.series1, chartColors.donut.series2],
            dataLabels: {
                enabled: false,
                formatter(val) {
                    return val;
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                fontSize: "2rem",
                                fontFamily: "Montserrat",
                            },
                            value: {
                                fontSize: "1rem",
                                fontFamily: "Montserrat",
                                formatter(val) {
                                    // eslint-disable-next-line radix
                                    return val;
                                },
                            },
                            total: {
                                show: true,
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                label: "مجموع الحجوزات",
                                formatter() {
                                    return "125";
                                },
                            },
                        },
                    },
                },
            },
            labels: ["الحجوزات المؤكدة", "الحجوزات الغير مؤكدة"],
            responsive: [
                {
                    breakpoint: 992,
                    options: {
                        chart: {
                            height: 380,
                        },
                        legend: {
                            position: "left",
                        },
                    },
                },
                {
                    breakpoint: 576,
                    options: {
                        chart: {
                            height: 320,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize: "1.5rem",
                                        },
                                        value: {
                                            fontSize: "1rem",
                                        },
                                        total: {
                                            fontSize: "1.5rem",
                                        },
                                    },
                                },
                            },
                        },
                        legend: {
                            show: true,
                            position: "left",
                        },
                    },
                },
            ],
        },
    },
    lineChartSimple: {
        series: [
            {
                name: "طلبات اليوم",
                data: [
                    100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280,
                    375,
                ],
            },
            {
                name: "طلبات الأمس",
                data: [
                    60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275,
                ],
            },
        ],
        chartOptions: {
            chart: {
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: true,
                position: "left",
                fontSize: "16px",
                fontFamily: "Montserrat",
                horizontalAlign: "center",
                markers: {
                    width: 12,
                    height: 12,
                    offsetX: 5,
                },
            },
            colors: [chartColors.area.series1, chartColors.area.series2],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            tooltip: {
                shared: false,
            },
            xaxis: {
                categories: [
                    "7 AM",
                    "8 AM",
                    "9 AM",
                    "10 AM",
                    "11 AM",
                    "12 AM",
                    "1 PM",
                    "2 PM",
                    "3 PM",
                    "4 PM",
                    "5 PM",
                    "6 PM",
                    "7 PM",
                    "8 PM",
                    "9 PM",
                ],
            },
            yaxis: {
                // opposite: isRtl,
            },
        },
    },
};
