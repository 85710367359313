<template>
  <div>
    <vue-apex-charts
      dir="ltr"
      type="bar"
      height="350"
      :options="barChartSimpleColumn.chartOptions"
      :series="barChartSimpleColumn.series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      barChartSimpleColumn: {
        series: [
          {
            name: "اجمالي الحجوزات",
            data: this.data,
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
              endingShape: "rounded",
            },
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            fontSize: "16px",
            fontFamily: "Montserrat",
            markers: {
              width: 12,
              height: 12,
              offsetX: 5,
            },
          },
          colors: ['#46B090'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          tooltip: {
            shared: false,
          },
          xaxis: {
            categories: [
              "حجوزات غير لائقة",
              "حجوزات لائقة",
              "مرحلة المختبر",
              "مرحلة الطبيب",
              "مرحلة الاشعة",
              "طلبات مؤكدة",
              "طلبات  غير مؤكدة",
              "طلبات المنصة ",
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
