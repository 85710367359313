<template>
  <section id="dashboard-analytics">
    <b-row v-if="getDashboard">
      <b-col cols="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                class="position-relative"
              >
                <b-card-title class="mb-0">
                  عدد المراكز المشاركة
                </b-card-title>

                <div class="dashboard-card">
                  <dashboard-centers-icon />
                  <h3>{{ getDashboard.count_centers }} مراكز</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                class="position-relative"
              >
                <b-card-title class="mb-0">
                  عدد عملاء المنصة
                </b-card-title>
                <div class="dashboard-card">
                  <dashboard-clients-icon />
                  <h3>{{ getDashboard.count_clients }} عملاء</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                class="position-relative"
              >
                <b-card-title class="mb-0">
                  عدد الحجوزات
                </b-card-title>
                <div class="dashboard-card">
                  <dashboard-bookings-icon />
                  <h3>{{ getDashboard.count_bookings }} حجوزات</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="getDashboard && getDashboard.bookings"
        cols="12"
      >
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                class="position-relative"
              >
                <b-card-title class="mb-0">
                  إحصائيات العمليات
                </b-card-title>
                <BarDashboardChart :data="getDashboard.bookings" />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                class="position-relative"
              >
                <b-card-title class="mb-0">
                  اخر الحجوزات في المنصة
                </b-card-title>
                <Bookings
                  :per-page="5"
                  :show-pagination="false"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import Bookings from '../components/Booking.vue'
import apexChatData from './apexChartData'
import DonutDashboardChart from './DonutDashboardChart'
import BarDashboardChart from './BarDashboardChart'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    VueApexCharts,
    DonutDashboardChart,
    BarDashboardChart,
    Bookings,
  },
  data() {
    return {
      apexChatData,
    }
  },
  computed: {
    ...mapGetters({
      getDashboard: 'getDashboard',
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      loadDashboard: 'loadDashboard',
    }),
  },
  mounted() {
    this.loadDashboard()
  },
}
</script>
