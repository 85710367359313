<template>
  <div>
    <vue-apex-charts
      type="donut"
      height="150"
      :options="donutDashboardChart.chartOptions"
      :series="countBookings"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    countBookings: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      donutDashboardChart: {
        series: [],
        chartOptions: {
          legend: {
            show: false,
            position: "left",
            fontSize: "16px",
            fontFamily: "Montserrat",
            horizontalAlign: "center",
            markers: {
              width: 12,
              height: 12,
              offsetX: 5,
            },
          },
          colors: ["#46B090"],
          dataLabels: {
            enabled: false,
            formatter(val) {
              return val;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "10px",
                    fontFamily: "Montserrat",
                    label: `مجموع الحجوزات`,
                    formatter(data) {
                      return data.globals.seriesTotals[0];
                    },
                  },
                },
              },
            },
          },
          labels: ["مجموع الحجوزات"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "left",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                  position: "left",
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
